import { baseConfig } from "config";
import { CategoryType, ServiceType } from "store/features/registration/types";
import { getAccessToken, renewAccessToken } from "./auth";
import { ErrorResponse, Requester } from "./requester";

export const getCategoriesService = async () => {
	let access = await getAccessToken();

	const request = () => {
		return Requester.get({
			url: `${baseConfig.baseURL}/categories/`,
			token: access,
			tokenType: "Bearer",
			success: (result): CategoryType[] => {
				return result;
			},
			error: async (error: ErrorResponse) => {
				console.log(error);
				if (error.code === 401) {
					access = await renewAccessToken();
					request();
				}
			}
		});
	};
	return request();
};

export const getServiceByCategoryService = async (category: string) => {
	let access = await getAccessToken();

	const request = () => {
		return Requester.get({
			url: `${baseConfig.baseURL}/categories/${category}/services`,
			token: access,
			tokenType: "Bearer",
			success: (result): ServiceType[] => {
				return result;
			},
			error: async (error: ErrorResponse) => {
				console.log(error);
				if (error.code === 401) {
					access = await renewAccessToken();
					request();
				}
			}
		});
	};
	return request();
};
