import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
	Button,
	ButtonBack,
	ListItem,
	DescriptionPage,
	Header,
	ServicesList,
	Steps,
	Wrapper,
	Loading
} from "components";

import {
	getCategoriesService,
	getServiceByCategoryService
} from "services/categories";
import { store } from "store";

import { setOfferedServices } from "store/features/registration/slice";
import { useDispatch } from "react-redux";
import {
	CategoryType,
	OfferedServiceType,
	ServiceType
} from "store/features/registration/types";
import { saveServiceProvider } from "services/service-provider";
import { triggerAnalyticsEvent } from "services/firebase";

export default function OfferedServicesPage() {
	const navigate = useNavigate();
	const personalData = store.getState().registration;
	const dispatch = useDispatch();
	const { isFirstAcess } = store.getState().flags;

	const [expanded, setExpanded] = useState<boolean>(false);
	const [activeCategoryId, setActiveCategoryId] = useState<string>("");
	const [categoryList, setCategoryList] = useState<any>([]);
	const [serviceList, setServiceList] = useState<any>([]);
	const [selectedServices, setSelectedServices] = useState(
		isFirstAcess ? ([] as OfferedServiceType[]) : personalData.offered_services
	);
	const [titleCategory, setTitleCategory] = useState<string>("");
	const [submitting, setSubmitting] = useState<boolean>(false);

	useEffect(() => {
		const fetchData = async () => {
			await getCategoriesService().then((resp) => {
				if (resp) {
					const loadedCategories = resp as CategoryType[];
					// eslint-disable-next-line array-callback-return
					loadedCategories.map((category: CategoryType) => {
						category.services = [];
					});
					setCategoryList(loadedCategories);
				}
			});
		};
		fetchData();
	}, []);

	const expandServiceList = () => {
		setExpanded(!expanded);
	};

	const loadAndExpandServiceList = async (category: CategoryType) => {
		expandServiceList();
		setTitleCategory(category.name);
		setActiveCategoryId(category.id);

		if (!category.services?.length) {
			setServiceList([]);
			await getServiceByCategoryService(category.id).then((resp) => {
				if (resp) {
					const loadedServices = resp as ServiceType[];
					setCategoryList(
						categoryList.map((item: CategoryType) => {
							if (item.id === category.id) {
								item.services = loadedServices;
							}
							return item;
						})
					);
					setServiceList(loadedServices);
				}
			});
		} else {
			setServiceList(category.services);
		}
	};

	const submit = async () => {
		setSubmitting(true);
		dispatch(setOfferedServices(selectedServices));
		triggerAnalyticsEvent("cp-set-offered-services");
		navigate("/cadastro/super-perfil");
		setSubmitting(false);
	};

	return !expanded ? (
		<>
			<Header title="Cadastro" trailing={<ButtonBack />} />
			<Steps active={2} steps={3} />
			<DescriptionPage
				title="Tipos de serviços"
				description="Escolha suas áreas de atuação e os serviços específicos que você realiza."
			/>
			<Wrapper>
				{categoryList.length ? (
					<div className="flex flex-col gap-4 mb-10">
						{categoryList?.map((categoryItem: CategoryType, idx: string) => (
							<ListItem
								name={categoryItem.name}
								image={categoryItem.image}
								key={`button-service-${idx}`}
								onClick={() => loadAndExpandServiceList(categoryItem)}
								selectedServices={selectedServices}
								categoryId={categoryItem.id}
							/>
						))}
					</div>
				) : (
					<Loading />
				)}

				<Button
					type="submit"
					onClick={submit}
					disabled={!selectedServices.length || submitting}
				>
					{submitting ? <Loading size="small" /> : "Prosseguir"}
				</Button>
			</Wrapper>
		</>
	) : (
		<ServicesList
			title={titleCategory}
			categoryId={activeCategoryId}
			services={serviceList}
			selectedServices={selectedServices}
			setSelectedServices={setSelectedServices}
			isOpen={expanded}
			handleToggleServicesList={expandServiceList}
			serviceProviderId={personalData.id}
		/>
	);
}
