import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import {
	FeedbackPage,
	MagicLinkPage,
	PersonalInformationPage,
	SuperPerfilPage,
	OfferedServicesPage,
	PrivacyPolicyPage,
	BudgetRequestPage,
	LandingPage,
	SignInPage,
	LoginPage
} from "pages";

import { SingleLayout, TwoColumnsLayout } from "layouts";
import PasswordRecoveryPage from "pages/password-recovery";
import SuccessPasswordResetPage from "pages/password-recovery/success";
import BudgetRequestMessageSentPage from "pages/budget-request/budget-request-message-sent";
import BudgetRequestRefusedPage from "pages/budget-request/budget-request-refused";
import BudgetRequestExpiredPage from "pages/budget-request/budget-request-expired";

function Routers() {
	const { pathname } = useLocation();

	useEffect(() => {
		setTimeout(() => {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth"
			});
		}, 100);
	}, [pathname]);

	return (
		<Routes>
			<Route path="/" element={<LandingPage />} />
			<Route path="" element={<TwoColumnsLayout />}>
				<Route path="/signin" element={<SignInPage />} />
				<Route path="/login" element={<LoginPage />} />
			</Route>

			<Route path="/cadastro" element={<SingleLayout />}>
				<Route
					path="informacoes-pessoais"
					element={<PersonalInformationPage />}
				/>
				<Route path="tipos-de-servicos" element={<OfferedServicesPage />} />
				<Route path="super-perfil" element={<SuperPerfilPage />} />
				<Route path="feedback" element={<FeedbackPage />} />
			</Route>

			<Route path="/privacy-policy" element={<PrivacyPolicyPage />} />

			<Route
				path="/password-recovery/:token"
				element={<PasswordRecoveryPage />}
			/>
			<Route path="/recovery-success" element={<SuccessPasswordResetPage />} />

			<Route path="/budget-request" element={<SingleLayout />}>
				<Route path=":budgetRequestId" element={<BudgetRequestPage />} />
				<Route
					path=":budgetRequestId/sent"
					element={<BudgetRequestMessageSentPage />}
				/>
				<Route
					path=":budgetRequestId/rejected"
					element={<BudgetRequestRefusedPage />}
				/>
				<Route
					path=":budgetRequestId/expired"
					element={<BudgetRequestExpiredPage />}
				/>
			</Route>
		</Routes>
	);
}

export default Routers;
