import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { format } from "date-fns";
import { placeholderProfilePicture } from "utils/constants";
import {
	OfferedServiceType,
	RegistrationState,
	SuperProfileState
} from "./types";

const initialState = {
	id: "",
	offered_services: [],
	certifications: [],
	photos: [],
	is_active: false,
	name: "",
	fiscal_document: "",
	phone: "",
	image: "",
	magic_link: "",
	description: "",
	super_profile: false,
	address_postal_code: "",
	address: "",
	address_number: "",
	address_additional_info: "",
	address_neighborhood: "",
	address_city: "",
	address_state: "",
	latitude: 0,
	longitude: 0,
	rating: 0,
	about_you: "",
	presentation_video_url: "",
	experience_start: "",
	accepted_user_terms: false,
	service_provider_user_id: 0
} as RegistrationState;

export const registrationSlice = createSlice({
	name: "registration",
	initialState,
	reducers: {
		setInitialPersonalInformation: (
			state,
			action: PayloadAction<RegistrationState>
		) => {
			state.id = action.payload.id;
			state.offered_services = action.payload.offered_services;
			state.certifications = action.payload.certifications;
			state.is_active = action.payload.is_active;
			state.name = action.payload.name;
			state.fiscal_document = action.payload.fiscal_document;
			state.phone = action.payload.phone;
			state.image = action.payload.image;
			state.magic_link = action.payload.magic_link;
			state.description = action.payload.description;
			state.super_profile = action.payload.super_profile;
			state.address_postal_code = action.payload.address_postal_code;
			state.address = action.payload.address;
			state.address_number = action.payload.address_number;
			state.address_additional_info = action.payload.address_additional_info;
			state.address_neighborhood = action.payload.address_neighborhood;
			state.address_city = action.payload.address_city;
			state.address_state = action.payload.address_state;
			state.latitude = action.payload.latitude;
			state.longitude = action.payload.longitude;
			state.rating = action.payload.rating;
			state.about_you = action.payload.about_you ?? "";
			state.presentation_video_url =
				action.payload.presentation_video_url ?? "";
			state.experience_start = action.payload.experience_start;
			state.accepted_user_terms = action.payload.accepted_user_terms;
			// state.photos = action.payload.photos;
		},
		setServiceProviderUserId: (state, action: PayloadAction<number>) => {
			state.service_provider_user_id = action.payload;
		},
		setRegistrationData: (state, action: PayloadAction<RegistrationState>) => {
			state.name = action.payload.name;
			state.fiscal_document = action.payload.fiscal_document;
			state.phone = action.payload.phone;
			state.address_postal_code = action.payload.address_postal_code;
			state.address = action.payload.address;
			state.address_number = action.payload.address_number;
			state.address_additional_info = action.payload.address_additional_info;
			state.address_neighborhood = action.payload.address_neighborhood;
			state.address_city = action.payload.address_city;
			state.address_state = action.payload.address_state;
		},
		setAcceptedUserTerms: (state, action: PayloadAction<boolean>) => {
			state.accepted_user_terms = action.payload;
		},
		setDefaultProfileImage: (state) => {
			state.image = placeholderProfilePicture;
		},
		setOfferedServices: (
			state,
			action: PayloadAction<OfferedServiceType[]>
		) => {
			state.offered_services = action.payload;
		},
		setSuperProfile: (state, action: PayloadAction<SuperProfileState>) => {
			state.certifications = action.payload.certifications;
			state.experience_start = format(
				new Date(action.payload.experience_start),
				"yyyy-MM-dd"
			);
			state.about_you = action.payload.about_you;
			state.presentation_video_url = action.payload.presentation_video_url;
			state.image = action.payload.image;
		},
		setLoggedIn: (state, action: PayloadAction<RegistrationState>) => {
			state.name = action.payload.name;
		},
		resetRegistration: () => initialState
	}
});

export const {
	setInitialPersonalInformation,
	setRegistrationData,
	setDefaultProfileImage,
	setAcceptedUserTerms,
	setOfferedServices,
	setServiceProviderUserId,
	setSuperProfile,
	setLoggedIn,
	resetRegistration
} = registrationSlice.actions;
export default registrationSlice.reducer;
