import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Checkbox, Content, Input } from "components";
import { resetAppPassword } from "services/auth";
import { LogoTrigo } from "assets/icons";
import { triggerAnalyticsEvent } from "services/firebase";
import styles from "./styles.module.scss";

const RegistrationSchema = Yup.object().shape({
	email: Yup.string().required("Campo obrigatório"),
	password: Yup.string()
		.required("Campo obrigatório")
		.min(8, "A senha precisa ter no mínimo 8 caracteres.")
		.matches(
			/^(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/,
			"A senha precisa conter um número e um símbolo (!@#$%^&*)"
		),
	passwordConfirmation: Yup.string()
		.required("Campo obrigatório")
		.oneOf([Yup.ref("password"), ""], "As senhas precisam ser iguais."),
	accept: Yup.bool()
});

export default function PasswordRecoveryPage() {
	const { token: reset_token } = useParams();
	const navigate = useNavigate();

	const formik = useFormik({
		initialValues: {
			email: "",
			password: "",
			passwordConfirmation: "",
			accept: false
		},
		validationSchema: RegistrationSchema,
		onSubmit: async (values) => {
			await resetAppPassword(
				reset_token ?? "",
				values.email,
				values.password,
				values.passwordConfirmation
			)
				.then(() => {
					triggerAnalyticsEvent("pwd-recovery-sucess");
					navigate("/recovery-success");
				})
				.catch(() => {
					toast.error("Houve um problema ao criar sua senha.", {
						position: "bottom-center",
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "light"
					});
				});
		}
	});

	const openTerms = () => {
		window.open(
			"https://grupotrigo.com.br/wp-content/uploads/2023/09/Grupo-Trigo-Politica-de-Privacidade.pdf",
			"_blank",
			"noreferrer"
		);
	};

	return (
		<div className={styles.registerContainer}>
			<div className={styles.logo}>
				<LogoTrigo />
			</div>
			<div>Crie sua conta para acessar o app</div>
			<div className={styles.card}>
				<form onSubmit={formik.handleSubmit}>
					<Content>
						<Input
							id="email"
							name="email"
							placeholder="Digite seu e-mail aqui"
							label="EMAIL"
							value={formik.values.email}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={formik.touched.email && formik.errors.email}
							errorMessage={formik.errors.email}
						/>
						<Input
							id="password"
							name="password"
							placeholder="Digite sua senha aqui"
							label="SENHA"
							type="password"
							value={formik.values.password}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={formik.touched.password && formik.errors.password}
							errorMessage={formik.errors.password}
						/>
						<Input
							id="passwordConfirmation"
							name="passwordConfirmation"
							placeholder="Digite sua senha aqui"
							label="REPITA A SENHA"
							type="password"
							value={formik.values.passwordConfirmation}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={
								formik.touched.passwordConfirmation &&
								formik.errors.passwordConfirmation
							}
							errorMessage={formik.errors.passwordConfirmation}
						/>
						<Checkbox
							name="accept"
							id="accept"
							value="1"
							label={
								<span>
									Estou de acordo com a{" "}
									<button type="button" role="link" onClick={() => openTerms()}>
										Política de Privacidade
									</button>
								</span>
							}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							checked={formik.values.accept}
						/>
					</Content>
					<div className={styles.submit}>
						<Button
							type="submit"
							disabled={!formik.values.accept || !formik.isValid}
						>
							Criar Conta
						</Button>
					</div>
				</form>
			</div>
		</div>
	);
}
