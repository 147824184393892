import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

import {
	Button,
	Content,
	Input,
	Loading,
	RegistrationHeader
} from "components";

import MaskHelper from "helpers/mask-helper";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { triggerAnalyticsEvent } from "services/firebase";
import { getPartnerUuid, partnerLogin } from "services/auth";
import {
	setInitialPersonalInformation,
	resetRegistration,
	setServiceProviderUserId
} from "store/features/registration/slice";
import { SelfRegisterTokens } from "store/features/tokens/types";
import { getServiceProvider } from "services/service-provider";
import { RegistrationState } from "store/features/registration/types";
import { setPartnerUuid, setUserId } from "store/features/tokens/slice";
import { setFirstAcess } from "store/features/flags/slice";
import styles from "./styles.module.scss";

const SignInSchema = Yup.object().shape({
	phone: Yup.string().required("Campo obrigatório"),
	password: Yup.string().required("Campo obrigatório")
});

export default function LoginPage() {
	const [submitting, setSubmitting] = useState<boolean>(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const formik = useFormik({
		initialValues: {
			phone: "",
			password: ""
		},
		validationSchema: SignInSchema,
		onSubmit: async (values) => {
			setSubmitting(true);
			dispatch(resetRegistration());

			await partnerLogin(values.phone, values.password)
				.then(async (account) => {
					if (account) {
						setSubmitting(false);
						const accountData = account as SelfRegisterTokens;
						dispatch(setServiceProviderUserId(accountData.user_id));
						dispatch(setUserId(accountData.user_id));

						await getPartnerUuid().then(async (uuid) => {
							if (uuid) {
								dispatch(setFirstAcess(uuid === ""));
								dispatch(setPartnerUuid(uuid as string));

								await getServiceProvider(uuid as string).then((resp) => {
									if (resp) {
										const personalData = resp as RegistrationState;
										dispatch(setInitialPersonalInformation(personalData));
										navigate("/cadastro/informacoes-pessoais");
									}
								});
							}
						});
					}
				})
				.catch((error) => {
					setSubmitting(false);
					toast.error("Algo saiu errado, tente novamente", {
						position: "bottom-center",
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "light"
					});
				});
		}
	});

	useEffect(() => {
		triggerAnalyticsEvent("cp-open-login");
	}, []);

	return (
		<div className={styles.container}>
			<RegistrationHeader
				name=""
				description="Simples, rápido e gratuito. Cadastre-se agora e amplie suas oportunidades de negócio."
			/>
			<form onSubmit={formik.handleSubmit}>
				<Content className={styles.registerContainer}>
					<Input
						id="phone"
						name="phone"
						placeholder="11 00000-00000"
						label="TELEFONE"
						inputLeading={<span className="pr-2 body">+55</span>}
						value={MaskHelper.phone(String(formik.values.phone)) || ""}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						error={formik.touched.phone && formik.errors.phone}
						errorMessage={formik.errors.phone}
						type="tel"
					/>
					<Input
						id="password"
						name="password"
						placeholder="Digite sua senha aqui"
						label="SENHA"
						type="password"
						value={formik.values.password}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						error={formik.touched.password && formik.errors.password}
					/>

					<Button
						type="submit"
						disabled={!formik.isValid || !formik.dirty || submitting}
					>
						{submitting ? <Loading size="small" /> : "Entrar"}
					</Button>

					<div className="mx-auto">
						<span>Ainda não possui uma conta?</span>

						<Button
							type="button"
							variant="text"
							onClick={() => navigate("/signin")}
						>
							Cadastrar conta
						</Button>
					</div>
				</Content>
			</form>
		</div>
	);
}
