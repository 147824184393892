import { baseConfig } from "config";
import { getAccessToken } from "services/auth";
import { RegistrationState } from "store/features/registration/types";
import { validateUrl } from "utils/functions";
import { Requester } from "./requester";

export const getServiceProvider = async (uuid: string | null = "") => {
	const access_token = await getAccessToken();
	return Requester.get({
		url: `${baseConfig.baseURL}/service-provider/${uuid}/`,
		token: access_token,
		tokenType: "Bearer",
		success: (result): RegistrationState => {
			result.offered_services = result.offered_services.map((item: any) => {
				return {
					id: item.id,
					service_provider: item.service_provider,
					service: item.service.id,
					service_name: item.service.name,
					category: item.service.category.id
				};
			});
			return result;
		},
		error: (error) => {
			throw error;
		}
	});
};

export const createNewServiceProvider = async (
	personalData: RegistrationState
) => {
	const access_token = await getAccessToken();

	const requestData = { ...personalData };
	if (
		requestData.image === null ||
		validateUrl(requestData.image) ||
		requestData.image === ""
	) {
		delete requestData.image;
	}

	return Requester.post({
		url: `${baseConfig.baseURL}/service-provider/`,
		token: `${access_token}`,
		tokenType: "Bearer",
		body: requestData,
		success: (result): RegistrationState => {
			return result;
		},
		error: (error) => {
			throw error;
		}
	});
};

export const saveServiceProvider = async (
	personalData: RegistrationState,
	id: string | null = ""
) => {
	const access_token = await getAccessToken();

	const requestData = { ...personalData };
	if (requestData.image === null || validateUrl(requestData.image)) {
		delete requestData.image;
	}

	return Requester.put({
		url: `${baseConfig.baseURL}/service-provider/${id}/`,
		token: `${access_token}`,
		tokenType: "Bearer",
		body: requestData,
		success: (result): RegistrationState => {
			return result;
		},
		error: (error) => {
			throw error;
		}
	});
};

export const getCEPService = async (cep: string) => {
	const access_token = await getAccessToken();
	return Requester.get({
		url: `${baseConfig.baseURL}/cep/${cep.replace("-", "")}`,
		token: `${access_token}`,
		tokenType: "Bearer",
		success: (result): AddressType => {
			return result;
		},
		error: (error) => {
			throw error;
		}
	});
};

export interface AddressType {
	bairro: string;
	cep: string;
	complemento: string;
	ddd: string;
	gia: string;
	ibge: string;
	localidade: string;
	logradouro: string;
	siafi: string;
	uf: string;
}
