import { useState, forwardRef, ForwardedRef } from "react";
import clsx from "clsx";

import { IcoEye, IcoEyeSlash } from "assets/icons";
import { TextSmall } from "components/TextInfo";

import styles from "./styles.module.scss";
import { InputProps, ShallowInputProps } from "./types";

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
	{
		id,
		label,
		type = "text",
		error,
		errorMessage,
		inputLeading,
		leading,
		trailing,
		containerClassName,
		...props
	}: InputProps,
	ref: ForwardedRef<HTMLInputElement>
) {
	const [showPassword, setShowPassword] = useState<boolean>(false);

	const handleShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const inputType =
		type === "password" ? (showPassword ? "text" : "password") : type;

	return (
		<div className={styles.inputWrapper}>
			<div
				className={clsx(
					styles.container,
					{
						[styles.error]: error,
						[styles.disabled]: props.disabled
					},
					containerClassName
				)}
			>
				{leading}
				<label
					className={clsx("overline_sm", "font-medium", "text-neutral-700")}
					htmlFor={id}
				>
					{label}
					<div className={clsx("flex flex-row items-center")}>
						{inputLeading}
						<input
							{...props}
							className="body"
							type={inputType}
							id={id}
							ref={ref}
						/>
					</div>
				</label>
				{trailing}
				{type === "password" && (
					<button type="button" onClick={handleShowPassword}>
						{showPassword ? <IcoEyeSlash /> : <IcoEye />}
					</button>
				)}
			</div>
			{error && errorMessage && <TextSmall label={errorMessage} error />}
		</div>
	);
});

export const ShallowInput = forwardRef(
	(
		{
			id,
			label,
			error,
			leading,
			trailing,
			errorMessage,
			...props
		}: ShallowInputProps,
		ref
	) => {
		return (
			<div className={styles.inputWrapper}>
				<div
					className={clsx(styles.container, {
						[styles.error]: error,
						[styles.disabled]: props.disabled
					})}
				>
					{leading}
					<label
						className={clsx("overline_sm", "font-medium", "text-neutral-700")}
						htmlFor={id}
					>
						{label}
						<input {...props} className="body" id={id} />
					</label>
					{trailing}
				</div>
				{error && errorMessage && <TextSmall label={errorMessage} error />}
			</div>
		);
	}
);
