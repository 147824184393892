import { IconProps } from "./types";

export default function IcoError({
	color = "#F65050",
	width = "24",
	height = "24",
	className
}: IconProps) {
	return (
		<svg
			width={width}
			height={height}
			className={className}
			viewBox="0 0 48 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M41.2811 32.0851L28.7225 10.6651C28.2147 9.84805 27.515 9.17565 26.688 8.70989C25.861 8.24413 24.9333 8 23.9905 8C23.0476 8 22.1199 8.24413 21.2929 8.70989C20.4659 9.17565 19.7663 9.84805 19.2585 10.6651L6.69982 32.0851C6.25618 32.8456 6.01505 33.7129 6.00068 34.5997C5.98631 35.4865 6.1992 36.3616 6.61795 37.1369C7.10209 38.0097 7.80285 38.7346 8.64862 39.2375C9.4944 39.7405 10.4548 40.0035 11.4318 39.9997H36.5491C37.5197 40.0103 38.4758 39.7575 39.321 39.2667C40.1662 38.7759 40.8705 38.0645 41.363 37.2043C41.794 36.4209 42.0137 35.5333 41.9993 34.6332C41.9849 33.7332 41.737 32.8535 41.2811 32.0851ZM38.4976 35.5372C38.3008 35.8851 38.0152 36.171 37.6718 36.3639C37.3283 36.5568 36.9401 36.6495 36.5491 36.6318H11.4318C11.0409 36.6495 10.6526 36.5568 10.3092 36.3639C9.96572 36.171 9.68014 35.8851 9.48335 35.5372C9.33964 35.2812 9.26399 34.9908 9.26399 34.6952C9.26399 34.3996 9.33964 34.1092 9.48335 33.8532L22.0584 12.4164C22.2843 12.1094 22.5764 11.8605 22.9116 11.689C23.2468 11.5176 23.6161 11.4284 23.9905 11.4284C24.3648 11.4284 24.7341 11.5176 25.0693 11.689C25.4046 11.8605 25.6966 12.1094 25.9226 12.4164L38.4812 33.8364C38.6305 34.0929 38.7107 34.3856 38.7135 34.6845C38.7164 34.9834 38.6419 35.2777 38.4976 35.5372Z"
				fill={color}
			/>
			<path
				d="M24 34C25.1046 34 26 33.1046 26 32C26 30.8954 25.1046 30 24 30C22.8954 30 22 30.8954 22 32C22 33.1046 22.8954 34 24 34Z"
				fill={color}
			/>
			<path
				d="M24 16C23.4696 16 22.9609 16.2107 22.5858 16.5858C22.2107 16.9609 22 17.4696 22 18V26C22 26.5304 22.2107 27.0391 22.5858 27.4142C22.9609 27.7893 23.4696 28 24 28C24.5304 28 25.0391 27.7893 25.4142 27.4142C25.7893 27.0391 26 26.5304 26 26V18C26 17.4696 25.7893 16.9609 25.4142 16.5858C25.0391 16.2107 24.5304 16 24 16Z"
				fill={color}
			/>
		</svg>
	);
}
