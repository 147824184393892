import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { TokensState } from "./types";

const initialState = {
	access: "",
	refresh: "",
	user_id: 0,
	partner_uuid: ""
} as TokensState;

export const tokensSlice = createSlice({
	name: "tokens",
	initialState,
	reducers: {
		setTokens: (state, action: PayloadAction<TokensState>) => {
			state.access = action.payload.access;
			state.refresh = action.payload.refresh;
		},
		setLogout: () => initialState,
		setAccessToken: (state, action: PayloadAction<string>) => {
			state.access = action.payload;
		},
		setRefreshToken: (state, action: PayloadAction<TokensState>) => {
			state.refresh = action.payload.refresh;
		},

		setPartnerUuid: (state, action: PayloadAction<string>) => {
			state.partner_uuid = action.payload;
		},

		setUserId: (state, action: PayloadAction<number>) => {
			state.user_id = action.payload;
		}
	}
});

export const {
	setTokens,
	setLogout,
	setAccessToken,
	setRefreshToken,
	setPartnerUuid,
	setUserId
} = tokensSlice.actions;
export default tokensSlice.reducer;
