import { IconProps } from "./types";

export default function IcoCheck({
	color = "#5E219C",
	width = "16",
	height = "16",
	className = ""
}: IconProps) {
	return (
		<svg
			width={width}
			height={height}
			className={className}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12.3958 4.41699L6.91665 9.89616L4.91665 7.87533C4.81248 7.79199 4.64581 7.79199 4.56248 7.87533L3.95831 8.47949C3.87498 8.56283 3.87498 8.72949 3.95831 8.83366L6.74998 11.6045C6.85415 11.7087 6.99998 11.7087 7.10415 11.6045L13.3541 5.35449C13.4375 5.27116 13.4375 5.10449 13.3541 5.00033L12.75 4.41699C12.6666 4.31283 12.5 4.31283 12.3958 4.41699Z"
				fill={color}
			/>
		</svg>
	);
}
