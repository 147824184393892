import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

import {
	Button,
	Checkbox,
	Content,
	Input,
	Loading,
	RegistrationHeader
} from "components";

import MaskHelper from "helpers/mask-helper";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
	setAcceptedUserTerms,
	resetRegistration,
	setServiceProviderUserId
} from "store/features/registration/slice";
import { SelfRegisterTokens } from "store/features/tokens/types";
import { IcoCheck } from "assets/icons";
import { triggerAnalyticsEvent } from "services/firebase";
import { createPartnerAccount } from "services/auth";
import { setFirstAcess } from "store/features/flags/slice";
import styles from "./styles.module.scss";

const SignInSchema = Yup.object().shape({
	phone: Yup.string().required("Campo obrigatório"),
	password: Yup.string()
		.required("Campo obrigatório")
		.min(8, "A senha precisa ter no mínimo 8 caracteres.")
		.matches(
			/^(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/,
			"A senha precisa conter um número e um símbolo (!@#$%^&*)"
		),
	passwordConfirmation: Yup.string()
		.required("Campo obrigatório")
		.oneOf([Yup.ref("password"), ""], "As senhas precisam ser iguais."),
	accept: Yup.bool().oneOf([true], "Você deve concordar com os termos")
});

interface PasswordInstructionProps {
	isValid: boolean;
	label: string;
}
function PasswordInstruction({
	isValid = false,
	label
}: PasswordInstructionProps) {
	return (
		<div className="flex flex-row items-center">
			<IcoCheck color={isValid ? "#5E219C" : "#868F9C"} className="mr-1" />
			<span className={isValid ? "text-neutral-900" : "text-neutral-400"}>
				{label}
			</span>
		</div>
	);
}

export default function SignInPage() {
	const dispatch = useDispatch();
	const [submitting, setSubmitting] = useState<boolean>(false);

	const navigate = useNavigate();

	const formik = useFormik({
		initialValues: {
			phone: "",
			password: "",
			passwordConfirmation: "",
			accept: false
		},
		validationSchema: SignInSchema,
		onSubmit: async (values) => {
			setSubmitting(true);
			dispatch(resetRegistration());
			dispatch(setAcceptedUserTerms(values.accept));

			await createPartnerAccount(
				values.phone,
				values.password,
				values.passwordConfirmation
			)
				.then(async (account) => {
					if (account) {
						setSubmitting(false);
						const accountData = account as SelfRegisterTokens;
						dispatch(setServiceProviderUserId(accountData.user_id));
						dispatch(setFirstAcess(true));
						navigate("/cadastro/informacoes-pessoais");
					}
				})
				.catch((error) => {
					setSubmitting(false);
					toast.error("Algo saiu errado, tente novamente", {
						position: "bottom-center",
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "light"
					});
				});
		}
	});

	useEffect(() => {
		triggerAnalyticsEvent("cp-open-self-register");
	}, []);

	const openTerms = () => {
		window.open(
			"https://grupotrigo.com.br/wp-content/uploads/2023/09/Grupo-Trigo-Politica-de-Privacidade.pdf",
			"_blank",
			"noreferrer"
		);
	};

	return (
		<div className={styles.container}>
			<RegistrationHeader
				name=""
				description="Simples, rápido e gratuito. Cadastre-se agora e amplie suas oportunidades de negócio."
			/>
			<form onSubmit={formik.handleSubmit}>
				<Content className={styles.registerContainer}>
					<Input
						id="phone"
						name="phone"
						placeholder="11 00000-00000"
						label="TELEFONE"
						inputLeading={<span className="pr-2 body">+55</span>}
						value={MaskHelper.phone(String(formik.values.phone)) || ""}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						error={formik.touched.phone && formik.errors.phone}
						errorMessage={formik.errors.phone}
						type="tel"
					/>
					<Input
						id="password"
						name="password"
						placeholder="Digite sua senha aqui"
						label="SENHA"
						type="password"
						value={formik.values.password}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						error={formik.touched.password && formik.errors.password}
					/>
					<div>
						<PasswordInstruction
							isValid={formik.values.password.length >= 8}
							label="Utilize no mínimo 8 caracteres"
						/>
						<PasswordInstruction
							isValid={/[0-9]/.test(formik.values.password)}
							label="Inclua pelo menos um número"
						/>
						<PasswordInstruction
							isValid={/.*[!@#$%^&*].*/.test(formik.values.password)}
							label="Adicione ao menos um símbolo (!@#$%+_=)"
						/>
					</div>

					<Input
						id="passwordConfirmation"
						name="passwordConfirmation"
						placeholder="Digite sua senha aqui"
						label="REPITA A SENHA"
						type="password"
						value={formik.values.passwordConfirmation}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						error={
							formik.touched.passwordConfirmation &&
							formik.errors.passwordConfirmation
						}
						errorMessage={formik.errors.passwordConfirmation}
					/>
					<Checkbox
						name="accept"
						id="accept"
						value="1"
						label={
							<span>
								Estou de acordo com a{" "}
								<button role="link" onClick={() => openTerms()}>
									Política de Privacidade
								</button>
								.
							</span>
						}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						checked={formik.values.accept}
					/>

					<Button type="submit" disabled={!formik.values.accept || submitting}>
						{submitting ? <Loading size="small" /> : "Iniciar cadastro"}
					</Button>
					<div className="mx-auto">
						<span>Já possui uma conta?</span>

						<Button
							type="button"
							variant="text"
							onClick={() => navigate("/login")}
						>
							Entrar na conta
						</Button>
					</div>
				</Content>
			</form>
		</div>
	);
}
