import { number } from "prop-types";
import { store } from "store";
import { renewAccessToken } from "./auth";

interface RequestOptions {
	method: string;
	headers?: { [key: string]: string };
	body?: string;
}

interface FetchSignature {
	url: string;
	token?: string;
	tokenType?: string;
	body?: string | object;
	success?: (data: any) => void;
	error?: (error: any) => void;
}

export interface ErrorResponse {
	message: string;
	code: string | number;
}

const getHeaders = (tokenType = "Token", authToken = "") => {
	return {
		"Content-Type": "application/json",
		Authorization: authToken !== "" ? `${tokenType} ${authToken}` : ""
	};
};

const handleResponse = async (
	response: any,
	options: FetchSignature,
	requestOptions: RequestOptions,
	retryCount = 0
): Promise<any> => {
	if (response.status === 401) {
		const { refresh } = store.getState().tokens;
		if (!refresh || retryCount >= 1) {
			return Promise.reject();
		}
		try {
			const newToken = await renewAccessToken();
			requestOptions.headers = getHeaders(options.tokenType, newToken);
			const retryResponse = await fetch(options.url, requestOptions);

			return await handleResponse(
				retryResponse,
				options,
				requestOptions,
				retryCount + 1
			);
		} catch (error) {
			return Promise.reject(error);
		}
	}
	if (!response.ok) {
		return response.json().then((data: any) => {
			const error = {
				message: (data && data.message) || response.statusText,
				code: response.status,
				body: data
			};
			return Promise.reject(error);
		});
	}
	if (response.headers.get("Content-Type") === "application/json") {
		return response.json();
	}
	return response;
};

const get = (options: FetchSignature) => {
	const requestOptions: RequestOptions = {
		method: "GET",
		headers: getHeaders(options.tokenType, options.token)
	};
	return fetch(options.url, requestOptions)
		.then((response) => handleResponse(response, options, requestOptions))
		.then((data) => options?.success?.(data))
		.catch((error) => options?.error?.(error));
};

const post = (options: FetchSignature) => {
	const requestOptions: RequestOptions = {
		method: "POST",
		headers: getHeaders(options.tokenType, options.token),
		body: JSON.stringify(options.body)
	};
	return fetch(options.url, requestOptions)
		.then((response) => handleResponse(response, options, requestOptions))
		.then((data) => options?.success?.(data))
		.catch((error) => options?.error?.(error));
};

const put = (options: FetchSignature) => {
	const requestOptions: RequestOptions = {
		method: "PUT",
		headers: getHeaders(options.tokenType, options.token),
		body: JSON.stringify(options.body)
	};
	return fetch(options.url, requestOptions)
		.then((response) => handleResponse(response, options, requestOptions))
		.then((data) => options?.success?.(data))
		.catch((error) => options?.error?.(error));
};

const deleteReq = (options: FetchSignature) => {
	const requestOptions: RequestOptions = {
		method: "DELETE",
		headers: getHeaders(options.tokenType, options.token)
	};
	return fetch(options.url, requestOptions)
		.then((response) => handleResponse(response, options, requestOptions))
		.then((data) => options?.success?.(data))
		.catch((error) => options?.error?.(error));
};

export const Requester = {
	get,
	post,
	put,
	delete: deleteReq
};
